import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication } from '@wix/members-area-app-definitions';

import { APP_TOKEN, PROFILE_PAGE_BOB_APP_DEF_ID } from '../../../constants';
import { MembersAreaPagePublicApi, RouteConfiguration, WidgetPluginPointer } from '../../types';

export const getMembersAreaPagePublicAPI = async (editorSDK: EditorSDK) => {
  const membersAreaPagePublicApi = await editorSDK.application.getPublicAPI(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
  });

  if (membersAreaPagePublicApi) {
    return membersAreaPagePublicApi as MembersAreaPagePublicApi;
  }

  throw new Error('[MAV2] Members Area Page PublicAPI is not found');
};

export const getInstalledApplications = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getInstalledWidgetPlugins();
};

export const getPublicApplications = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getPublicApplications();
};

export const getRoutes = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getRoutes();
};

export const setRouteData = async (editorSDK: EditorSDK, route: RouteConfiguration) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.updateRoute(route);
};

export const addApplicationsToSlots = async (editorSDK: EditorSDK, applications: IntegrationApplication[]) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.addWidgetsPlugins(applications);
};

export const removeApplicationsFromSlots = async (editorSDK: EditorSDK, applications: WidgetPluginPointer[]) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  const widgetsIds = applications.map(({ widgetId }) => widgetId);

  return membersAreaPageAPI.removeWidgets(widgetsIds);
};

export const uninstallProfilePageBoB = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.uninstall();
};
